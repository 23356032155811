.toggleIcon {
  padding: 8px;
  box-sizing: content-box;
  cursor: pointer;
}

.subtitle {
  padding: 8px 20px;
  margin: 0;

  color: var(--t-common-text) !important;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;

  background-color: var(--t-special-item);
}

.organizationItem {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;

  font-size: 17px !important;
}

.dropdownItemContent {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.dropdownItemSeparated {
  position: relative;
  margin: 8px 0;
}

.dropdownItemSeparated::before {
  content: "";
  position: absolute;
  left: 0;
  top: -8px;
  width: 100%;
  height: 1px;
  background-color: var(--t-panel-border);
}

.iconPro {
  display: block;
  margin-left: 8px;
  padding: 6px 8px;
  line-height: 1;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;
}

.iconProThemeWhite {
  background-color: #fff;
  color: #000;
}

.iconProThemeDark {
  background-color: #1A3838;
  color: #fff;
}

