.content {
  z-index: 6000;
  min-width: 160px;
  overflow-x: hidden;
  overflow-y: auto;

  background-color: var(--t-panel-bg);
  border: 1px solid var(--t-panel-border);
  border-radius: 4px;
  outline: none;
}

.item {
  display: block;
  padding: 8px 20px;
  color: var(--t-common-text) !important;
  font-size: 14px;
  line-height: 1.5;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  transition: all .2s ease;
}

.item:hover {
  background-color: var(--t-list-item-hover);
}

.itemActive {
  background-color: var(--t-accent-element) !important;
  color: var(--t-accent-text-contrast-color) !important;
}
