.listItem {
  color: var(--t-common-text);
  cursor: pointer;
  font-size: 14px;
  line-height: 1.5;
  padding: 8px 20px;
  transition: all .2s ease;
}

.listItemActive {
  background-color: var(--t-accent-element);
  color: var(--t-accent-text-contrast-color);
}
