.root {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 28px 12px;
  z-index: 1055;

  display: none;
  justify-content: center;
  align-items: center;

  overflow-y: auto;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  overflow-x: hidden;
  overflow-y: auto;

  background-color: rgba(0, 0, 21, .5);
}

.centerer {
  margin: 0.5rem auto;
  min-height: calc(100% - 1rem);

  display: flex;
  align-items: center;
}

.container {
  position: relative;
  padding: 1rem;
  width: 100%;

  background-color: var(--t-modal-bg);
  border-radius: 4px;
  box-shadow: 0 24px 38px 3px rgba(var(--t-elevation-base), .14),
  0 9px 46px 8px rgba(var(--t-elevation-base), .12),
  0 11px 15px -7px rgba(var(--t-elevation-base), .2);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 1rem;
  padding-right: 1.5rem;
}

.closeBtn {
  position: absolute;
  right: 0;
  top: 0;

  padding: 1rem;
  background-color: transparent;
  border: none;

  color: var(--t-contrast-text);
  font-size: 21px;
  font-weight: 700;
  line-height: 1;

  opacity: .5;
  transition: all .2s ease;

  cursor: pointer;
}
.closeBtn:hover {
  opacity: 1;
}

.body {
  padding: 1rem 0;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 8px;
  padding-top: 0.75rem;
}

.rootVisible {
  display: block;
}

@media screen and (min-width: 576px) {
  .rootSize--md .centerer {
    max-width: 500px;

    margin: 1.75rem auto;
    min-height: calc(100% - 3.5rem);
  }
}

@media screen and (min-width: 992px) {
  .rootSize--lg .centerer,
  .rootSize--xl .centerer {
    max-width: 800px;
  }
}

@media screen and (min-width: 1200px) {
  .rootSize--xl .centerer {
    max-width: 1140px;
  }
}
